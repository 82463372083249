import React from "react";
import { IntroCont, MainCont, Title, Subtitle, Jenny } from "./style";
import james from "../../media/avatar.jpg";
import Typical from "react-typical";
import { ChevronsDown } from "react-feather";

export default function Intro() {

	return (
		<IntroCont>
			<MainCont>
				<div>
					<Title>
						<div>Hello, Welcome!</div>
						<div>Something About me...</div>
					</Title>
					<Subtitle>
						
							<span>I am{" "}</span>
						
						<Typical
							wrapper="span"
							steps={steps}
							loop={Infinity}
							className="typing"
						/>
					</Subtitle>
				</div>
				<Jenny src={james}></Jenny>
			</MainCont>
			<a href="#aboutme">
				<ChevronsDown className="arrow" />
			</a>
		</IntroCont>
	);
}

const steps = [
	"a Full Stack Engineer.",
	1500,
	"Creative.",
	1500,
	"Detail-oriented.",
	1500,
	"Adaptive.",
	1500,
	"Collaborative.",
	1500,
	"Eager to learn.",
	1500
];
