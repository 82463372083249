import React from "react";
import Spotify from "../../media/screenshots/MusicApp.png"
import FootPrint from "../../media/screenshots/CarbonFootPrint.png"
import pilim from "../../media/screenshots/frontend_backend_diagram.svg";
import Galaxy from "../../media/screenshots/Galaxy.png";
import Api from "../../media/screenshots/APIs.png"
import Movie from "../../media/screenshots/Movie.png";
import Gallery from "../../media/screenshots/Gallery2.png";
import GameDev from "../../media/screenshots/gameDev.png";
import aws from "../../media/screenshots/aws.png";
import elb from "../../media/screenshots/elb.png";
import gpt from "../../media/screenshots/MentorGPT.jpg"



import { ProjectsCont } from "./style";
import EachProject from "./EachProject";

const projects = [
	{
		name: "MentorGPT",
		description:
			"I'm thrilled to introduce MentorGPT, an exciting application I've been working on that makes extracting information from PDF documents a breeze and allows you to engage in natural language conversations with OpenAI's cutting-edge GPT-4.0 language model. Whether you're a researcher, student, or professional, MentorGPT empowers you to effortlessly extract text from PDF files and have meaningful conversations with the extracted content.",
		tech: "Python / LangChain / OpenAI / HuggingFace  ",
		githubLink: [
			"https://github.com/JamesAHo/MentorGPT",
			
		],
		deployedSite: "https://github.com/JamesAHo/MentorGPT",
		
		img: gpt,
		medium:""
	},
	{
		name: "Large scale online Conference Application",
		description:
			"This project was developed to implement a web application that could support a large number of concurrent users accessing it simultaneously. The web application was designed for use at a major conference that drew over 10,000 in-person and online attendees from all around the world. The aim was to create a reliable and scalable solution to handle the high demand of users during the conference.",
		tech: "AWS Cloud / EC2 / ELB / DynamoDB / CloudFront/ Edge Location  ",
		githubLink: [
			"",
			
		],
		deployedSite: "",
		
		img: elb,
		medium:"https://medium.com/@thai.ho184/implementation-of-a-scalable-web-application-using-the-services-of-aws-elastic-beanstalk-dynamodb-31d15a0bac85"
	},
	{
		name: "AWS Migration Project",
		description:
			"In a hands-on project as a Full Stack Engineer, I was assigned to migrate a workload from a Corporate DataCenter to AWS. My duties involved implementing the Lift & Shift (rehost) method to relocate both the application and database to AWS, while also transferring their respective data.",
		tech: "AWS Cloud / EC2 / VPC / RDS  ",
		githubLink: [
			"",
			
		],
		deployedSite: "",
		
		img: aws,
		medium:"https://medium.com/@thai.ho184/migration-of-a-workload-running-in-a-corporate-data-center-to-aws-using-the-amazon-ec2-and-rds-fefe45ff1388"
	},
	{
		name: "Personal Spotify",
		description:
			"A music application for friends and family to keep up with the latest music releases. This application mainly uses Shazam APIs as the main source of music data. Users can be able to search for their favorite artists and get the hottest music trend based on their location.",
		tech: "React / REDUX / JavaScript / APIs / ",
		githubLink: [
			"https://github.com/JamesAHo/Personal-Spotify",
			
		],
		deployedSite: "https://personal-spotify.netlify.app/",
		
		img: Spotify,
		wireframes:
			""
	},
	{
		name: "Object Oriented Programming",
		description:
			"Currently developing a 2D game using Object Oriented Programming. Stay tuned for future updates.",
		tech: "HTML / CSS / JavaScript",
		githubLink: [
			"https://github.com/JamesAHo/Game-Development",
			
		],
		deployedSite: "https://jamesaho.github.io/Game-Development/",
		
		img: GameDev,
		wireframes:
			""
	},
	
	{
		name: "Cosine Galaxy",
		description:
			"What happened If all the stars in this universe rotate like a cosine function? I decided to use threeJS to make a 3D grid and visualize all of the stars into Cosine function.",
		tech: "React / ThreeJS / Three Fiber / JavasScript",
		githubLink: [
			"https://github.com/JamesAHo/Cosine-Galaxy",
			
		],
		deployedSite: "https://galaxy-by-james.netlify.app/",
		
		img: Galaxy,
		wireframes:
			""
	},
	{
		name: "Architecture Gallery",
		description:
			"As a developer with background in Architecture, I find it fascinating by looking at other architecture's art. I created this gallery by using HTML/CSS, keyframes animation and grid template area. This gallery helped me to have better perspective in front-end engineer and I found it interesting to implement animations using simple CSS ",
		tech: "HTML / CSS",
		githubLink: [
			"https://github.com/JamesAHo/Front-end-Grid",
			
		],
		deployedSite: "https://jamesaho.github.io/Front-end-Grid/",
		
		img: Gallery,
		wireframes:
			""
	},
	{
		name: "Social Media API",
		description:
			"Developed APIs endpoints for social media. Able to perform CRUD operations on different endpoints. For more information please visit github link.",
		tech: "NodeJs / Express / Mongoose / Insomnia",
		githubLink: [
			"https://github.com/JamesAHo/Social-Network-API",
			
		],
		deployedSite: "https://github.com/JamesAHo/Social-Network-API",
		
		img: Api,
		wireframes:
			""
	},
	{
		name: "SQL Apollo/GraphQL CRUD Operations",
		description: `Using Graphql, TypeScript, TypeORM and Apollo client to do CRUD operations on SQL database. GrapQL APIs are organized in terms of types and fields. Able to access the full capabilites of data from a single endpoints.
		Used types to ensure apps only ask for what's possible and provide clear and helpful errors. Apps can use types to avoid writting manual parsing code `,
		tech: "SQL / TypeORM / NodeJS / Express / Apollo / TypeScript / React",
		githubLink: "https://github.com/JamesAHo/MySQL-GraplQl-CRUD",
	
		img: pilim,
		
	},
	{
		name: "ScenAntics",
		description:
			"Application for Movie game night, where the users can view a list of current popular suggested films to watch and once starting the game then can select a genre and will arrive to the game prompt and drink or do an activity when a specific scene appears. Users can create a login and save their credentials in our database.This project was done by James A,Carolina Hernandez and Sabrina at UCLA.",

		tech: "MongoDB / Express / NodeJS / React / JavaScript / GraphQL / Redux / HTML / CSS / Tailwindcss / Apollo / JWT / Google Cloud ",
		githubLink: [
			"https://github.com/JamesAHo/ScenAntics",
			
		],
		deployedSite: "https://crucial-pagoda-364901.uw.r.appspot.com/",
		
		img: Movie,
		wireframes:
			""
	},
	{
		name: "Carbon Footprint Calculator & Air Quality",
		description:
			"A website that lets users calculate carbon footprint, check for air quality status based on current location. Collaborated with Sandra Mendoza who works at  Pacific HealthWorks, LLC.",

		tech: "JavaScript / HTML / CSS / Bootstrap / APIs ",
		githubLink: [
			"https://github.com/sandraxmm/Carbon-Footprint-Tracker",
			
		],
		deployedSite: "https://sandraxmm.github.io/Carbon-Footprint-Tracker/",
		
		img: FootPrint,
		wireframes:
			""
	}
];

export default function Projects() {
	return (
		<ProjectsCont id="projects">
			<div className="section-title">PROJECTS</div>
			{projects.map((project, idx) => (
				<EachProject key={idx} number={`0${idx + 1}`} project={project} />
			))}
		</ProjectsCont>
	);
}
