import styled from "styled-components";

export const HeaderCont = styled.div`
	position: sticky;
	top: 0;
	
	/* background-color: white; */
	background: rgba(255, 255, 255, 0.75);
	backdrop-filter: blur(3px);

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
	justify-content: space-between;
	font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
	z-index: 2;

	margin: 0;
	padding: 0.5rem 2rem 0.5rem 2rem;
	border-bottom: thin solid;
`;

export const Logo = styled.button`

	cursor: pointer;
	text-decoration: none;
	
	background-color: transparent;
	border: none;
	text-align: center;
	padding: 0;

	@media only screen and (min-width: 320px) {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 15px;
			font-weight: normal;
			text-transform: uppercase;
			outline: none;
	}

	@media only screen and (min-width: 480px) {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 15px;
			font-weight: normal;
			text-transform: uppercase;
			outline: none;
	}

	@media only screen and (min-width: 768px) {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 15px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
	}

	@media only screen and (min-width: 992px) {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 15px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
	}

	@media only screen and (min-width: 1200px) {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 35px;
			font-weight: normal;
			text-decoration: bold;
			
	}
`;

const NavBarTemplate = styled.ul`
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;

	a {
		text-decoration: none;
		color: inherit;
	}
`;

// export const NavBar = styled.ul`
export const NavBar = styled(NavBarTemplate)`
	@media only screen and (min-width: 320px) {
		font-size: 1rem;
	}

	@media only screen and (min-width: 480px) {
		column-gap: 2rem;
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 12px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
	}

	@media only screen and (min-width: 768px) {
			column-gap: 2.5rem;
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 12px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
	}

	@media only screen and (min-width: 1200px) {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
		font-size: 14px;
		font-weight: normal;
		text-decoration: none;
		outline: none;
	}
`;

export const MobileHeaderCont = styled.div`
	position: sticky;
	top: 0;
	background: rgba(255, 255, 255, 0.75);
	backdrop-filter: blur(5px);
	z-index: 2;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	justify-content: space-between;
	font-family: DM Serif Display;

	margin: 0;
	padding: 1rem 2rem 1rem 2rem;
	border-bottom: thin solid #294234;

	.hamburger {
		display: flex;
		align-self: center;
	}
`;

// export const MobileNav = styled.ul`
export const MobileNav = styled(NavBarTemplate)`
	display: none;
	position: absolute;
	top: 80px;
	left: 0;

	background-color: #eae3d7;
	/* background: rgba(255, 255, 255, 0.75); */

	padding: 10px 0 10px 0;
	width: 100vw;

	> div {
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		@media only screen and (min-width: 320px) {
			font-size: 1.125rem;
		}

		@media only screen and (min-width: 480px) {
			font-size: 1.25rem;
		}
	}
`;
