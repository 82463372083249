import styled from "styled-components";

export const AboutCont = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	justify-content: center;
	padding-top: calc(100vh * 0.25);
	padding-bottom: calc(100vh * 0.15);
	width: 100%;
`;

export const TopCont = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	

	@media only screen and (min-width: 320px) {
		flex-direction: column;
		row-gap: 1em;
		margin-bottom: 1.5rem;
	}

	@media only screen and (min-width: 480px) {
		flex-direction: column;
		row-gap: 1em;
		margin-bottom: 2rem;
	}

	@media only screen and (min-width: 768px) {
		flex-direction: column;
		row-gap: 1em;
		margin-bottom: 2.5rem;
	}

	@media only screen and (min-width: 992px) {
		flex-direction: column;
		row-gap: 1em;
		margin-bottom: 3rem;
	}

	@media only screen and (min-width: 1200px) {
		flex-direction: row;
		column-gap: 4rem;
		margin-bottom: 3.5rem;
	}
`;

export const AcnhBlob = styled.img`
	position: static;
	box-shadow: 0 0 0 #000;
	border: 1px solid #f5f3f6;
	box-shadow: 1px 5px 10px 5px rgb(33 33 33 / 20%);
	overflow: visible;


	@media only screen and (min-width: 320px) {
		width: 20rem;
	}

	@media only screen and (min-width: 480px) {
		width: 20rem;
	}

	@media only screen and (min-width: 768px) {
		width: 17rem;
	}

	@media only screen and (min-width: 992px) {
		width: 21.875rem;
	}

	@media only screen and (min-width: 1200px) {
		width: 25rem;
	}
`;

export const TextCont = styled.div`
	display: flex;
	flex-direction: column;
	font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
	line-height:1.5;
	

	ul {
		
		list-style: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
	}
	li {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
				font-size: 15px;
				font-weight: normal;
				text-decoration: none;
				outline: none;
	}

	@media only screen and (min-width: 480px) {
		ul {
			font-size: 1.125rem;
		}
	}

	@media only screen and (min-width: 768px) {
		ul {
			font-size: 1.25rem;
		}

		width: 40rem;
	}
`;

export const TechStack = styled.div`
	display: flex;
	justtify-content: center;
	align-items: center;
	padding: 2.5rem 0 2.5rem 2.5rem;
	
	border-style: dashed;
	
	
	

	span {
		div:first-child {

			
			
				font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
				font-size: 10px;
				font-weight: normal;
				text-decoration: none;
				outline: none;
			}
		}
	}

	@media only screen and (min-width: 320px) {
		flex-direction: column;
		row-gap: 1.25rem;
		text-align: center;
		width: 100%;

		span {
			div:first-child {
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 15px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
			}
		}
	}

	@media only screen and (min-width: 480px) {
		width: 28.125rem;

		span {
			div:first-child {
				font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
				font-size: 13px;
				font-weight: normal;
				text-decoration: none;
				outline: none;
			}
		}
	}

	@media only screen and (min-width: 768px) {
		text-align: center;
		width: 31.25rem;

		span {
			div:last-child {
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 12px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
			}
		}
	}

	@media only screen and (min-width: 992px) {
		flex-direction: row;
		justify-content: space-around;
		text-align: start;
		width: 59.375rem;

		span {
			div:first-child {
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 13px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
			}
		}
	}

	@media only screen and (min-width: 1200px) {
		width: 71.875rem;
		
		span {
			display: flex;
			justify-content: center;
			align-items: center;
			div:first-child {
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 12px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
			}
		}
	}
`;
