import styled from "styled-components";

export const FooterCont = styled.footer`
	
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	
	box-shadow: 1px 5px 10px 5px rgb(33 33 33 / 20%);
	

	@media only screen and (min-width: 320px) {
		height: 3.5rem;
		font-size: 0.625rem;
	}

	@media only screen and (min-width: 480px) {
		
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			font-size: 15px;
			font-weight: normal;
			text-decoration: none;
			outline: none;
	}
`;
