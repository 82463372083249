import styled, { keyframes } from "styled-components";

export const IntroCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  height: calc(100vh - 150px);

  a {
    text-decoration: none;
    color: inherit;

    > .arrow {
      text-decoration: none;

      width: 40px;
      height: 40px;
      top: 100vh;
      transform: translateY(200%);
      z-index: -1;
    }
  }
`;

export const MainCont = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (min-width: 320px) {
    flex-direction: column-reverse;
    row-gap: 1.25rem;
    text-align: center;

    > div {
      width: 100vw;
    }
  }

  @media only screen and (min-width: 480px) {
    flex-direction: column-reverse;
    row-gap: 1.25rem;
    text-align: center;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: column-reverse;
    row-gap: 1.25rem;
    text-align: center;

    > div {
      width: 40.5rem;
    }
  }

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    width: 100%;
    text-align: start;
    justify-content: space-around;
  }

  @media only screen and (min-width: 1200px) {
    justify-content: space-evenly;
    width: 85%;
  }
`;

export const Title = styled.div`
  font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light",
    monospace;
  @media only screen and (min-width: 320px) {
    font-family: "Input Mono Normal Extra Light",
      "Input Mono Normal Extra Light", monospace;
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    outline: none;
  }

  @media only screen and (min-width: 480px) {
    font-family: "Input Mono Normal Extra Light",
      "Input Mono Normal Extra Light", monospace;
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    outline: none;
  }

  @media only screen and (min-width: 768px) {
    font-family: "Input Mono Normal Extra Light",
      "Input Mono Normal Extra Light", monospace;
    font-size: 20px;
    font-weight: normal;
    text-decoration: none;
    outline: none;
  }

  @media only screen and (min-width: 992px) {
    font-family: "Input Mono Normal Extra Light",
      "Input Mono Normal Extra Light", monospace;
    font-size: 25px;
    font-weight: normal;
    text-decoration: none;
    outline: none;
  }

  @media only screen and (min-width: 1200px) {
    font-family: "Input Mono Normal Extra Light",
      "Input Mono Normal Extra Light", monospace;
    font-size: 30px;
    font-weight: normal;
    text-decoration: none;
    outline: none;
  }
`;
// make keyframe here
const scale = keyframes`
	from, to {
		transform: scale(0);
	50% {
		transform: scale(1)
	}
	}
`;
const rotate = keyframes`
from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(180deg);
  }
  	}
`;
const background = keyframes`
from {
    background-position: 0% center;
  }
  
  to {
    background-position: -200% center;
  }
}
`;

export const Subtitle = styled.div`
.magic > .magic-star > svg > path {
	fill: var(--violet);
  }
  
  h1 > .magic > .magic-text {
	animation: background-pan 3s linear infinite;
	background: linear-gradient(
	  to right,
	  var(--purple),
	  var(--violet),
	  var(--pink),
	  var(--purple)
	);
	background-size: 200%;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	white-space: nowrap;
  }
  span {
    animation: ${background} 3s linear infinite;
    background: linear-gradient(
      to right,
      rgb(123, 31, 162),
      rgb(103, 58, 183),
      rgb(244, 143, 177)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
  }

  @media only screen and (min-width: 320px) {
    font-size: 1.125rem;
    margin-top: 1rem;
  }

  @media only screen and (min-width: 480px) {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  @media only screen and (min-width: 768px) {
    font-size: 1.75rem;
    margin-top: 1rem;
  }

  @media only screen and (min-width: 992px) {
    margin-top: 1.25rem;
    font-family: "Input Mono Normal Extra Light",
      "Input Mono Normal Extra Light", monospace;
    font-size: 35px;
    font-weight: normal;
    text-decoration: none;
    outline: none;
  }

  @media only screen and (min-width: 1200px) {
    margin-top: 1.25rem;
    font-family: "Input Mono Normal Extra Light",
      "Input Mono Normal Extra Light", monospace;
    font-size: 40px;
    font-weight: normal;
    text-decoration: none;
    outline: none;
  }
`;

export const Jenny = styled.img`
  position: static;
  box-shadow: 0 0 0 #000;
  border: 1px solid #f5f3f6;
  box-shadow: 1px 5px 5px 5px rgb(33 33 33 / 20%);
  overflow: visible;
  @media only screen and (min-width: 320px) {
    width: 20rem;
  }

  @media only screen and (min-width: 480px) {
    width: 18rem;
  }

  @media only screen and (min-width: 768px) {
    width: 17rem;
  }

  @media only screen and (min-width: 992px) {
    width: 21.875rem;
  }

  @media only screen and (min-width: 1200px) {
    width: 25rem;
  }
`;
