import styled from "styled-components";

export const ProjectsCont = styled.div`
	
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
	justify-items: center;
	justify-content: center;
	align-self: center;
	padding-left: 1rem;

	@media only screen and (min-width: 1200px) {
		width: 72rem;
	}
`;

export const TopCont = styled.div`
	
	display: flex;
	justify-content: space-between;
	column-gap: 1.25rem;

	@media only screen and (min-width: 320px) {
		flex-direction: column-reverse;

		div + div {
			font-size: 13px;
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			line-height:1.2;
			text-decoration: none;
			font-weight: normal;
			
		}

		> img {
			align-self: center;
		}
	}

	@media only screen and (min-width: 768px) {
		div + div {
			font-size: 13px;
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
			line-height:1.2;
			text-decoration: none;
			font-weight: normal;
		}
	}

	@media only screen and (min-width: 992px) {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
		line-height:1.2;
		
		font-size: 13px;
		flex-direction: row;
		text-decoration: none;
		font-weight: normal;
	}
`;

export const ProjectName = styled.div`
	font-family: Oakesgrotesk,sans-serif;
	margin-bottom: 1.25rem;
	

	@media only screen and (min-width: 320px) {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
		font-size: 12px;
		margin-top: 1.5rem;
		text-decoration: none;
		font-weight: normal;
	}

	@media only screen and (min-width: 480px) {
		font-size: 1.375rem;
	}

	@media only screen and (min-width: 768px) {
		font-size: 1.5rem;
	}

	@media only screen and (min-width: 992px) {
		font-size: 1.75rem;
		margin-top: 0;
	}
`;

export const BtmCont = styled.div`
	display: flex;
	
	flex-direction: column;
	row-gap: 1.5rem;
	margin-bottom: 4rem;
	
	

	

	a {
		font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
		font-size: 10px;
		text-decoration: none;
		box-sizing: border-box;
		border: 1px solid #7B7B7C ;
		cursor: pointer;
		padding: 0.5rem 1.125rem 0.5rem 1.125rem;
		text-align:center;
		outline: none; !important;
	
	}

	a:hover {
		background: #7B7B7C;
	}

	@media only screen and (min-width: 320px) {
		> div:first-child {
			color: #7B7B7C;
			font-weight: bold;
			font-size: 9px;
		}

		> div:last-child {
			display: flex;
			flex-direction: row;
			row-gap: 0.75rem;
			column-gap: 1.25rem;
			flex-wrap: wrap;
			font-size: 12px;
		}
	}

	@media only screen and (min-width: 480px) {
		> div:first-child {
			display: flex;
			align-items: center;
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
				font-size: 12px;
				font-weight: normal;
				text-decoration: none;
				outline: none;
		}

		> div:last-child {
			font-family: "Input Mono Normal Extra Light", "Input Mono Normal Extra Light", monospace;
				font-size: 12px;
				font-weight: normal;
				text-decoration: none;
				outline: none;
		}
	}
`;

export const ImgCont = styled.img`
	border: 1px solid #f5f3f6;
	box-shadow: 1px 9px 8px 5px rgb(33 33 33 / 20%);
	overflow: visible;

	@media only screen and (min-width: 320px) {
		width: 22rem;
	}

	@media only screen and (min-width: 480px) {
		width: 26.5rem;
	}

	@media only screen and (min-width: 768px) {
		width: 28.125rem;
	}

	@media only screen and (min-width: 992px) {
		width: 26.5rem;
	}

	@media only screen and (min-width: 1200px) {
		width: 28.125rem;
	}
`;
