import React from "react";
import { ContactCont, ContactCard, SNS, DownloadBtn } from "./style";

import resume from "../../media/resume.pdf";
import { AtSign, Linkedin, GitHub } from "react-feather";

export default function Contact() {
	return (
		<ContactCont id="contact">
			
			<ContactCard>
				<div className="basic-info">
					<div>James A Ho</div>
					<div>thai.ho184@gmail.com</div>
				</div>
				<div className="bold job">Full Stack Developer</div>
				<SNS>
					<a
						href="mailto:thai.ho184@gmail.com"
						target="_blank"
						rel="noreferrer"
					>
						<AtSign />
					</a>
					<a
						href="https://github.com/JamesAHo"
						target="_blank"
						rel="noreferrer"
					>
						<GitHub />
					</a>
					<a
						href="https://www.linkedin.com/in/james-anh/"
						target="_blank"
						rel="noreferrer"
					>
						<Linkedin />
					</a>
				</SNS>
				<DownloadBtn href={resume} download>
					Download Resume
				</DownloadBtn>
				<div>
					<div>City</div>
					<div className="bold">San Jose, CA</div>
				</div>
			</ContactCard>
		</ContactCont>
	);
}
