import React from "react";
import { PortfolioCont, SemiCircle } from "./style";

export default function Portfolio() {
	return (
		<PortfolioCont>
			<SemiCircle>
				<div>
					<span>
						<a
							href="https://github.com/JamesAHo"
							target="_blank"
							rel="noreferrer"
						>
						GitHub
						</a>
						
						
					</span>
				</div>
				<div>Made by James.</div>
			</SemiCircle>
		</PortfolioCont>
	);
}
