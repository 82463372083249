import React from "react";
import { AboutCont, TopCont, AcnhBlob, TextCont, TechStack } from "./style";
import acnhblob from "../../media/genRoom.jpg";

export default function About() {
  return (
    <AboutCont id="aboutme">
      <TopCont>
        <AcnhBlob src={acnhblob} />
        <TextCont>
          <div className="section-title">ABOUT ME</div>
          <ul>
            <li>
              Welcome to my remarkable journey! I initially forged my path as an
              architectural engineer, sculpting the physical world with
              precision. However, I couldn't resist the allure of coding, which
              added a new dimension to my skills and allowed me to craft digital
              wonders. From founding an esports team to self-teaching web
              development, my path has been characterized by unwavering
              curiosity and dedication to growth. Enrolling in UCLA's coding
              bootcamp was transformative, turning me into a versatile
              full-stack engineer with expertise in application architecture, databases,
              REST APIs, and cloud deployment. But what truly sets me apart is
              the transferable soft skills I've refined along the way. My
              architectural background has taught me the art of design thinking,
              attention to detail, and the ability to envision and construct
              complex systems. These skills seamlessly translate into the world
              of software engineering. Each day, I eagerly embrace coding
              challenges, driven by a fervor for innovation and problem-solving.
              I've discovered that my architectural mindset is the foundation
              for creating not only physical structures but also scalable,
              efficient, and elegant software solutions. With a solid
              foundation, unwavering commitment, and a unique blend of
              architectural and coding expertise, I'm primed to shine as a
              software engineer. Join me on this captivating journey of code and
              creation!{" "}
            </li>
          
          </ul>
        </TextCont>
      </TopCont>
      <TechStack>
        <span>
          <div className="stack-title">
            Language & Tech Stack 🎨
            <div>JavaScript / TypeScript / React / C# / Python / MERN MEAN / .NET </div>
          </div>
        </span>
        <span>
          <div>
            Designs
            <div>
              Figma / Adobe / MUI / styled-components / TailwindCss{" "}
            </div>
          </div>
        </span>
        <span>
          <div>
            Development 🤖
            <div>
              AWS / Cloud Computing / NodeJS / HuggingFace / Google Cloud / OpenAI /
              Generative AI / Azure{" "}
            </div>
          </div>
        </span>
      </TechStack>
    </AboutCont>
  );
}
